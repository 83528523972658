@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Custom marquee animation */
@keyframes marquee {
  0% {
    transform: translateX(-55.3%);
  }
  100% {
    transform: translateX(0);
  }
}

.animate-marquee {
  display: flex;
  animation: marquee 15s linear infinite;
  width: 200%;
}

/* Set global background color using our new color */
body {
  @apply bg-lightgray;
}

@layer utilities {
  .scrollbar-thin {
    scrollbar-width: thin;
  }
  .scrollbar-thumb-gray-400 {
    scrollbar-color: #a0aec0 #cbd5e0;
  }
  .scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
  }
}

.scrollbar-thin::-webkit-scrollbar {
  width: 6px;
}
.scrollbar-thin::-webkit-scrollbar-track {
  background: #cbd5e0;
}
.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: #a0aec0;
}
.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background-color: #718096;
}
